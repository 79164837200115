import {html, LitElement, render} from "lit";
import {customElement, property} from "lit/decorators.js";
import {Router, Routes} from "../Router";
import store from "../services/Store";

import "./LiveBoard";
import {LiveBoard} from "./LiveBoard";
import {connect} from "../helpers/ConnectStoreMixin";
import {LiveBoardController} from "../controllers/LiveBoardController";
import BoardEventsHandlers from "../services/BoardEventsHandlers";
import FloatingWidgetManager from "../services/FloatingWidgetManager";
import {type ClientSDK, type IFollozeState} from "client-sdk";

declare global {
    interface Window {
        FollozeState: IFollozeState;
        FlzClientSDK: ClientSDK | "pending"
        Munchkin: any; // TODO(Elad) - add type here so you can use it in cookieMatching/Marketo.ts
        "fz-external-header": LitElement | undefined;
        WidgetClientVersion: string;
        WidgetClientBranch: string | undefined;
        designer: any; // TODO(Rotem) - extract the LiveBoardDesigner type client-sdk or wait for monorepo
    }
}

@customElement('flz-liveboard-app')
export class App extends connect(store)(LitElement) {
    @property({type: Number})

    public route: Routes;
    @property()
    public baseRoute: string;
    private router: Router;
    public board: LiveBoard;
    public controller: LiveBoardController;
    private listener: BoardEventsHandlers;
    private floatingWidgetsManager: FloatingWidgetManager;

    constructor() {
        super();
        console.debug("live board start");
        console.time("board is ready!");
        console.time("enrichment started");
        this.printVersionWarning();

        this.router = new Router(this, store);
        this.board = document.createElement("live-board") as LiveBoard;
        this.board.setAttribute("tabindex", "0");
        this.controller = new LiveBoardController(this.router, this.board, store);
        this.board.controller = this.controller;
        this.router.controller = this.controller; // TODO - figure out how to manage the dependencies btw controller and router
        this.listener = new BoardEventsHandlers(this.board, this.controller);
        this.floatingWidgetsManager = new FloatingWidgetManager(this.board, this.listener);
        this.listener.startListening();

        window.onerror = (errorMsg: Event | string, url: string, lineNumber: number | undefined) =>
            this.globalErrorHandler(errorMsg, url, lineNumber);

    }

    stateChanged(state: unknown) {
        this.controller.stateChanged(state);
    }

    async connectedCallback() {
        super.connectedCallback();
        await this.controller.initialize();
        this.append(this.board);
    }

    protected createRenderRoot(): HTMLElement | DocumentFragment {
        return this;
    }

    private globalErrorHandler(errorMsg: Event | string, url: string, lineNumber: number | undefined) {
        console.error(url, lineNumber, errorMsg);
        return false;
    }

    private printVersionWarning() {
        const widgetsUrl = this.getCookie("widgets_endpoint");
        const liveBoardUrl = this.getCookie("live_board_endpoint");
        const designerUrl = this.getCookie("designer_endpoint");
        const widgetsVersion = localStorage.getItem("widgetsVersion");
        if (widgetsUrl || liveBoardUrl || designerUrl || widgetsVersion) {
            if (widgetsVersion) {
                console.warn(`overriding widgets version from ${window["WidgetClientVersion"]} to ${widgetsVersion}`);
                window["WidgetClientVersion"] = widgetsVersion;
            }
            const warning = document.createElement("div");
            render(html`
            <div class="alert alert-warning" 
                 style="display: inline-block;position: fixed;z-index: 999999;
                 bottom: 0;left: 0;padding: 0px 5px 10px 10px;
                 background: var(--fz-color-danger-2, palevioletred);
                 font-size: 12px;letter-spacing: 1px;line-height: 3px;">
                <p>You are using a different client version!</p>
                <a href="#" @click="${(e: MouseEvent) => this.removeVersionClientCookies(e)}">
                    click here
                </a> to remove the version cookies.
            </div>`, warning);
            document.body.append(warning);
        }
    }

    private removeVersionClientCookies(e: MouseEvent) {
        e.preventDefault();
        this.eraseCookie("widgets_endpoint");
        this.eraseCookie("live_board_endpoint");
        this.eraseCookie("designer_endpoint");
        localStorage.removeItem("widgetsVersion");
        // @ts-ignore
        location.reload(true);
    }

    private getCookie(name: string) {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
    }

    private eraseCookie(name: string) {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}
