/**
 * This service will detect if the user is idle by following these rules:
 * 1) Once a minute the service will check if the user moved his mouse
 * 2) If he did nothing happens
 * 3) If the user doesn't move his mouse for 2 minutes, idle is set to true
 * 4) The only way to escape idle mode is to click somewhere on the page
 */

const TIME_FOR_IDLE = 1000 * 60 * 10; // 10 minutes
const DETECTION_TIME_BUFFER = 1000 * 30; // 30 seconds, to lessen stress with event listening

export type MouseAction = {
    clientX: number;
    clientY: number;
    pageX: number;
    pageY: number;
    movementX: number;
    movementY: number;
    date: Date;
};

type IdleServiceOptions = {
    timeForIdle?: number;
    detectionTimeBuffer?: number;
};

type IdleCallBack = (isIdle: boolean) => void;

export default class IdleService {
    private timeForIdle: number;
    private detectionTimeBuffer: number;
    private idleTimeout: NodeJS.Timeout;
    private eventListenersBufferTimer: NodeJS.Timeout;
    private idleServiceClients: IdleCallBack[] = [];
    private abortEventListenersController: AbortController = new AbortController();
    private currentIdleStatus: boolean = false;
    public lastMouseMove: MouseAction;

    constructor({timeForIdle = TIME_FOR_IDLE, detectionTimeBuffer = DETECTION_TIME_BUFFER}: IdleServiceOptions = {}) {
        this.timeForIdle = timeForIdle || TIME_FOR_IDLE;
        this.detectionTimeBuffer = detectionTimeBuffer || DETECTION_TIME_BUFFER;
        this.setActive();
        window.addEventListener("beforeunload", () => this.destroy());
    }

    destroy() {
        clearTimeout(this.eventListenersBufferTimer);
        clearTimeout(this.idleTimeout);
        this.abortEventListenersController.abort();
        this.idleServiceClients = [];
    }

    private attachEventListeners() {
        clearTimeout(this.eventListenersBufferTimer);
        this.eventListenersBufferTimer = setTimeout(() => {
            document.body.addEventListener("mousemove", this.listenToMouseMove, {once: true, signal: this.abortEventListenersController.signal});
        }, this.detectionTimeBuffer);
    }

    private setActive() {
        this.notifyIdleChange(false);
        this.waitForIdle();
    }

    private waitForIdle() {
        this.attachEventListeners();

        clearTimeout(this.idleTimeout);
        this.idleTimeout = setTimeout(() => {
            this.notifyIdleChange(true);
        }, this.timeForIdle);
    }

    private notifyIdleChange(isIdle: boolean) {
        if(this.currentIdleStatus === isIdle) return;

        this.currentIdleStatus = isIdle;
        this.idleServiceClients.forEach((callBack) => callBack(isIdle));
    }

    private listenToMouseMove = (e: MouseEvent) =>{
        this.lastMouseMove = { // it's temporary and will be removed in the future
            clientX: e.clientX,
            clientY: e.clientY,
            pageX: e.pageX,
            pageY: e.pageY,
            movementX: e.movementX,
            movementY: e.movementY,
            date: new Date(),
        };
        this.setActive();
    };

    public onIdleChange(clientCallBack: IdleCallBack) {
        this.idleServiceClients.push(clientCallBack);
    }
}
